import {
  REACT_APP_ANAPOLIS_TOKEN,
  REACT_APP_ARAGUAINA_TOKEN,
} from "config/envs";

type Cities = {
  [key in CityIds]: City;
};

export const cities: Cities = {
  araguaina: {
    name: "Araguaína",
    id: "araguaina",
    groupId: 1,
    token: REACT_APP_ARAGUAINA_TOKEN,
    lat: -7.1924,
    lng: -48.2049,
  },
  anapolis: {
    name: "Anápolis",
    id: "anapolis",
    groupId: 2,
    token: REACT_APP_ANAPOLIS_TOKEN,
    lat: -16.3285,
    lng: -48.9534,
  },
};
