import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from "./envs";

export const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

const databaseRef = firebase.firestore();
export const authRef = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
// functions.useFunctionsEmulator("http://192.168.0.194:5001");

export const FIREBASE_AUTH_PERSIST = firebase.auth.Auth.Persistence.LOCAL;

export const busRoutesRef = (city: CityIds) =>
  databaseRef.collection("cities").doc(city).collection("routes");

export const citiesRef = databaseRef.collection("cities");
export const ridesRef = databaseRef.collection("rides");
export const ratesRef = databaseRef.collection("rates");
export const promoRef = databaseRef.collection("offers");
export const userRef = databaseRef.collection("users");
export const chatRef = databaseRef.collection("chat");
export const feedRef = databaseRef.collection("feedbacks");
export const driversRef = databaseRef.collection("drivers");
export const newPlacesReportRef = databaseRef.collection("new-place-report");
export const lostAndFoundRef = databaseRef
  .collection("cities")
  .doc("araguaina")
  .collection("lost_and_found");
export const referralRef = databaseRef.collection("referral/bonus/amount");
export const adminIfoRef = databaseRef.collection("adm").doc("info");
export const singleUserRef = (uid: string) =>
  databaseRef.collection("adm-users").doc(uid);
