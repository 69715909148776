import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  CircularProgress,
  Typography,
  Grid,
  Checkbox,
  Switch,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";

// hooks
import { useStores } from "stores";

type Props = {
  open: boolean;
  route: BusRoute;
  onClose(): void;
};

const useStyles = makeStyles({
  paper: {
    minWidth: 650,
  },
});

export function ScheduleModal({ open, route, onClose }: Props) {
  const { busRoutes, auth } = useStores();
  const classes = useStyles();

  const [weeklySchedule, setWeeklySchedule] = useState<ScheduleTime[]>([]);
  const [saturdaySchedule, setSaturdaySchedule] = useState<ScheduleTime[]>([]);
  const [sundaySchedule, setSundaySchedule] = useState<ScheduleTime[]>([]);

  const setSchedule = {
    week: setWeeklySchedule,
    saturday: setSaturdaySchedule,
    sunday: setSundaySchedule,
  };

  const handleSave = async () => {
    await busRoutes.updateRouteSchedule(
      route.id,
      weeklySchedule,
      saturdaySchedule,
      sundaySchedule,
    );
    handleClose();
  };

  const handleClose = () => {
    setWeeklySchedule([]);
    setSaturdaySchedule([]);
    setSundaySchedule([]);
    onClose();
  };

  const isAdmin = auth.userData?.access_type === "admin";
  const hours: ScheduleTime[] = [];
  const startTime = moment("05:55", "HH:mm");
  const endTime = moment("23:40", "HH:mm");

  while (startTime.isSameOrBefore(endTime)) {
    hours.push({
      time: startTime.format("HH:mm"),
      from_terminal: false,
    });
    startTime.add(5, "minutes");
  }

  useEffect(() => {
    if (open && route.id) {
      setWeeklySchedule(route.weekly_bus_schedule || []);
      setSaturdaySchedule(route.saturday_bus_schedule || []);
      setSundaySchedule(route.sunday_bus_schedule || []);
    }
  }, [open, route]);

  const handleChange = (
    checked: boolean,
    hour: ScheduleTime,
    type: "week" | "saturday" | "sunday",
  ) => {
    setSchedule[type]((prev) =>
      checked ? [...prev, hour] : prev.filter((h) => h.time !== hour.time),
    );
  };

  const handleTimeFrom = (
    checked: boolean,
    hour: ScheduleTime,
    type: "week" | "saturday" | "sunday",
  ) => {
    setSchedule[type]((prev) =>
      prev.map((h) =>
        h.time === hour.time ? { ...h, from_terminal: checked } : h,
      ),
    );
  };

  const renderTimeList = (
    time: ScheduleTime,
    index: number,
    type: "week" | "saturday" | "sunday",
  ) => {
    const schedule = {
      week: weeklySchedule,
      saturday: saturdaySchedule,
      sunday: sundaySchedule,
    }[type];

    const checked = schedule.find((h) => h.time === time.time) !== undefined;
    const fromTerminal = schedule.find(
      (h) => h.time === time.time,
    )?.from_terminal;

    return (
      <div key={`${type}-${index}`} style={{ display: "flex", width: 190 }}>
        <FormControlLabel
          id="time"
          control={<Checkbox color="secondary" />}
          label={time.time}
          value={time}
          disabled={!isAdmin}
          onChange={(_, checked) => handleChange(checked, time, type)}
          checked={checked}
        />
        <FormControlLabel
          id="fromTerminal"
          checked={fromTerminal}
          disabled={!checked || !isAdmin}
          onChange={(_, checked) => handleTimeFrom(checked, time, type)}
          control={<Switch name="checkedB" color="primary" />}
          label={fromTerminal ? "Centro" : "Bairro"}
        />
      </div>
    );
  };

  return (
    <Dialog
      classes={classes}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Horários da rota: {route?.route_number} - {route?.name}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={2} style={{ minWidth: 600 }}>
          <Grid item xs={4}>
            <Typography>Semanal</Typography>
            {hours.map((hour, index) => renderTimeList(hour, index, "week"))}
          </Grid>
          <Grid item xs={4} style={{ background: "#ddd" }}>
            <Typography>Sábado</Typography>
            {hours.map((hour, index) =>
              renderTimeList(hour, index, "saturday"),
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography>Domingo</Typography>
            {hours.map((hour, index) => renderTimeList(hour, index, "sunday"))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          disabled={busRoutes.isRequestLoading}
          color="primary"
        >
          {busRoutes.isRequestLoading ? (
            <CircularProgress size={18} />
          ) : (
            "Salvar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
