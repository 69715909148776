import { useEffect } from "react";
import MaterialTable, { Column } from "material-table";
import moment from "moment";
import "moment/locale/pt-br";

export const states = [
  { label: "Acre", value: "AC" },
  { label: "Alagoas", value: "AL" },
  { label: "Amapá", value: "AP" },
  { label: "Amazonas", value: "AM" },
  { label: "Bahia", value: "BA" },
  { label: "Ceará", value: "CE" },
  { label: "Distrito Federal", value: "DF" },
  { label: "Espírito Santo", value: "ES" },
  { label: "Goiás", value: "GO" },
  { label: "Maranhão", value: "MA" },
  { label: "Mato Grosso", value: "MT" },
  { label: "Mato Grosso do Sul", value: "MS" },
  { label: "Minas Gerais", value: "MG" },
  { label: "Pará", value: "PA" },
  { label: "Paraíba", value: "PB" },
  { label: "Paraná", value: "PR" },
  { label: "Pernambuco", value: "PE" },
  { label: "Piauí", value: "PI" },
  { label: "Rio de Janeiro", value: "RJ" },
  { label: "Rio Grande do Norte", value: "RN" },
  { label: "Rio Grande do Sul", value: "RS" },
  { label: "Rondônia", value: "RO" },
  { label: "Roraima", value: "RR" },
  { label: "Santa Catarina", value: "SC" },
  { label: "São Paulo", value: "SP" },
  { label: "Sergipe", value: "SE" },
  { label: "Tocantins", value: "TO" },
];

// hooks
import { useStores } from "stores";
import { Grid, Typography } from "@material-ui/core";

export function NewPlacesReport() {
  const { feedbacks } = useStores();

  useEffect(() => {
    feedbacks.loadPlaceReports();
  }, []);

  const statesWithTotal = feedbacks.newPlaceReports
    .reduce((acc, report) => {
      const state = acc.find((s) => s.value === report.state);
      if (state) {
        state.total += 1;
      } else {
        acc.push({ value: report.state, total: 1 });
      }

      return acc;
    }, [])
    .sort((a, b) => b.total - a.total)
    .slice(0, 10);

  const columns: Column<PlaceReport>[] = [
    { title: "Nome", field: "user_name", editable: "never" },
    { title: "Cidade", field: "city", editable: "never" },
    {
      title: "Estado",
      customSort: (a, b) => a.state.localeCompare(b.state),
      render(data) {
        const state = states.find((state) => state.value === data.state);
        return state?.label;
      },
    },
    {
      title: "Data Envio",
      render: (row) => (
        <p>
          {moment(row.created_at.toDate())
            .locale("pt-br")
            .format("D [de] MMM, YYYY [às] HH:mm")}
        </p>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Total: {feedbacks.newPlaceReports.length}
          </Typography>
          <Typography>
            {statesWithTotal.map((state) => (
              <span key={state.value}>
                {state.value} - {state.total}
                {" | "}
              </span>
            ))}
          </Typography>
        </Grid>
      </Grid>
      <MaterialTable
        title="Inidcações"
        columns={columns}
        isLoading={feedbacks.isLoading}
        data={feedbacks.newPlaceReports}
        options={{ pageSize: 150 }}
        localization={{
          toolbar: { searchPlaceholder: "Pesquisar" },
          header: { actions: "Excluir" },
          body: {
            editRow: { deleteText: "Excluir esta mensagem ?" },
            emptyDataSourceMessage: "Nenhum feedback encontrado",
          },
          pagination: {
            labelRowsSelect: "Resultados",
            labelDisplayedRows: "{from}-{to} de {count}",
            firstTooltip: "Primeira página",
            previousTooltip: "Página anterior",
            nextTooltip: "Próxima página",
            lastTooltip: "Última página",
          },
        }}
        editable={{
          onRowDelete: (oldData) => feedbacks.deletePlaceReport(oldData.id),
        }}
      />
    </>
  );
}
