import axios from "axios";
import {
  REACT_APP_GPS_ADMIN_TRACK_API_KEY,
  REACT_APP_GPS_ARAGUAINA_TRACK_API_KEY,
} from "config/envs";

export const gpsApi = (city: CityIds = "araguaina") => {
  const tokens = {
    anapolis: REACT_APP_GPS_ADMIN_TRACK_API_KEY,
    araguaina: REACT_APP_GPS_ARAGUAINA_TRACK_API_KEY,
  };
  const token = tokens[city];

  return axios.create({
    baseURL: "https://rastreamento.moveciti.com/api",
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

export const gpsApiResquest = axios.create({
  baseURL: "https://rastreamento.moveciti.com/api",
});
