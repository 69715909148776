import { memo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  TextField,
} from "@material-ui/core";

// hooks
import { useStores } from "stores";

type Props = {
  open: boolean;
  route: BusRoute;
  updateRoute: React.Dispatch<React.SetStateAction<BusRoute>>;
  onClose(): void;
};

export const EditRouteModal = memo(
  ({ open, route, updateRoute, onClose }: Props) => {
    const { busRoutes } = useStores();

    const handleUpdate = async () => {
      await busRoutes.updateRouteInfo(
        route.id,
        route.route_number,
        route.name,
        route.description,
      );
      onClose();
    };

    const handleUpdateRoute = (e: React.ChangeEvent<HTMLInputElement>) => {
      updateRoute({ ...route, [e.target.id]: e.target.value });
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Editar rota</DialogTitle>
        <DialogContent>
          <DialogContentText>Escolha o ônibus</DialogContentText>
          <TextField
            id="route_number"
            label="Nome"
            variant="outlined"
            fullWidth
            style={{ marginBottom: 16 }}
            value={route.route_number}
            onChange={handleUpdateRoute}
          />
          <TextField
            id="name"
            label="Nome"
            variant="outlined"
            fullWidth
            style={{ marginBottom: 16 }}
            value={route.name}
            onChange={handleUpdateRoute}
          />
          <TextField
            id="description"
            label="Descrição"
            variant="outlined"
            fullWidth
            style={{ marginBottom: 16 }}
            value={route.description}
            onChange={handleUpdateRoute}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            disabled={busRoutes.isRequestLoading}
          >
            Cancelar
          </Button>
          <Button
            disabled={busRoutes.isRequestLoading}
            onClick={handleUpdate}
            color="primary"
          >
            {busRoutes.isRequestLoading ? (
              <CircularProgress size={18} />
            ) : (
              "Salvar"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
