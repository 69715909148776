import React from "react";
import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

import { AppMenu } from "./AppMenu";

import { useStores } from "stores";
import { cities } from "constants/cities";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    height: "calc(100vh - 64px)",
    padding: theme.spacing(3),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(1.5),
    },
  },
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { auth } = useStores();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isFullAccess = auth.userData?.access_type === "admin";
  const citiesArray = Object.keys(cities).map((key) => cities[key]);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  function handleCloseDrawer() {
    setMobileOpen(false);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetCity = (city: City) => {
    setAnchorEl(null);
    if (city.id === auth.citySelected.id) {
      return handleClose();
    }

    window.location.reload();
    auth.setCity(city);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{ backgroundColor: "white" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon style={{ color: "black" }} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            style={{ color: "black", flexGrow: 1 }}
          >
            Painel Administrativo
          </Typography>
          {isFullAccess && (
            <div>
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <Typography>{auth.citySelected.name}</Typography>
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!anchorEl}
                onClose={handleClose}
              >
                {citiesArray.map((city) => (
                  <MenuItem key={city.id} onClick={() => handleSetCity(city)}>
                    {city.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/*@ts-ignore */}
        <Hidden mdDown implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }} // Better open performance on mobile.
          >
            <AppMenu closeMenu={handleCloseDrawer} />
          </Drawer>
        </Hidden>
        {/*@ts-ignore */}
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open
          >
            <AppMenu closeMenu={handleCloseDrawer} />
          </Drawer>
        </Hidden>
      </nav>
      <Container className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </Container>
    </div>
  );
}

export default ResponsiveDrawer;
