import { gpsApi, gpsApiResquest } from "./request";

export const openSession = async (token: string): Promise<any> => {
  try {
    const { data } = await gpsApiResquest.get(`session?token=${token}`, {
      withCredentials: true,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getDevices = async (city: CityIds): Promise<GpsDevice[]> => {
  try {
    const { data = [] } = await gpsApi(city).get("devices");

    return data;
  } catch (error) {
    console.log(error, "getDevices");
  }
};
